.btn {
  width: 100% !important;
  padding: 8px 25px !important;
  background: none !important;
  border: none !important;
  cursor: pointer;
  outline: none;

  &__yellow {
    font-size: 13px !important;
    font-weight: 600 !important;
    background: #fceed4 !important;
    color: #d58c00 !important;
    border-radius: 7px !important;

  }

  &__blue {
    font-weight: 700 !important;
    font-size: 13.7952px !important;
    line-height: 18px !important;
    color: #1e4db7 !important;
    // border: 0.7664px solid #1e4db7 !important ;
    // border-radius: 7px !important;
    margin: 2px;
    padding: 0 !important;
  }

  &__blue-fill {
    @extend .btn__blue;
    color: #fff !important;
    background-color: #1e4db7 !important;
    font-weight: 600 !important;
    font-size: 13px !important;
  }

  &__blue-sky {
    color: #1e4db7 !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    border: none !important;
    padding: 0 !important;
    border-radius: 7px !important;
  }
  &__blue-sky-fill {
    @extend .btn__blue-sky;
    padding: 8px 0 !important;
    background-color: #edfaff !important;
  }

  &__view-more {
    font-weight: 700 !important;
    font-size: 15px !important;
    line-height: 18px !important;
    color: #1e4db7 !important;
    border: 0.7664px solid #1e4db7 !important ;
    border-radius: 7px !important;
    margin: 5px;
    padding: 0 !important;
    height: 30px;
  }
  &__view-more:hover{
    background-color: #1e4db7;
    color: #fff;
  }

}
