.container {
  &__layout {
    background: #f5f5f5 !important;
    display: flex;
    justify-content: space-around;
    width: 100%
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 25px;
    width: 100%;
  }
}
