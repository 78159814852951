.cart-header-info {
    background: #E2F7FF;
    border: 1px solid #5ECFFF;
    margin-top: 13px;
    > span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: #000000;
        padding: 5px;
    }
}

.cart-header-product {
    padding-left: 15px;
    padding-right: 15px;
    background: #FFFFFF;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.3% 3% 1.3% 3%;
    margin-top: 9px;
    width: 100%;
    > div > span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        display: flex;
        align-items: center;
        color: #000000;
    }
    .item-1 {
        width: 16px;
        margin-right: 2%;
    }
    .item-2 {
        width: 50%;
        margin-right: 1%;
    }
    .item-3 {
        width: 10%;
        margin-right: 1%;
        min-width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .item-4 {
        width: 10%;
        margin-right: 1%;
        min-width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .item-5 {
        width: 10%;
        margin-right: 1%;
        min-width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .item-6 {
        width: 10%;
        min-width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}