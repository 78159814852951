.title {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    > h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        color: #1E4DB7;
    }
}