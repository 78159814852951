.container {
  display: flex;
  width: 95%;
  height: 25em;
  margin-top: 20px;
}

.chart {
  background-color: rgb(255, 255, 255);
  height: 100%;
  width: 50%;
  margin-right: 10px;
  border-radius: 8px;
}

.spending {
  height: 100%;
  width: 50%;
  border-radius: 8px;
  background-color: white;
}

.title {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  font-family: Inter;
}

.search {
  height: 42px;
  width: 10%;
  margin-right: 10em;
}

.business-logo {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 50%;
}

.tablePoint {
  width: 95%;
  height: 455px;
  background-color: white;
  padding: 20px;
  border-radius: 14px;
}
.card_bg{
  width: 100%;
  height: 157px;
  left: 0px;
  top: 0px;
  background: #FFFFFF;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
}

.profile {
  &__avatar-box {
    height: 157px;
    width: 295px;
    max-width: 295px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: end;
    padding: 5.1%;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 14px 0 0 14px;

  }
  &__avatar {
    border-radius: 50%;
    width: 52.8px;
    height: 52.8px;
  }
  &__name {
    width: 100%;
    height: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 2px;
  }
  &__online {
    width: 100%;
    height: 15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    margin-bottom: 0;

  }
  &__avatar-bg {
    color: aliceblue;
    border: 3px solid #afe7ff;
    border-radius: 50%;
    width: 57px;
    height: 57px;
    margin-right: 4%;
  }

}
.point {
  padding-top: 3.9%;
  padding-bottom: 3.9%;
  padding-left: 4.1%;
  width: 100%;
  .point_text {
    height: 17px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #6C757D;
    margin-bottom: 4px;
  }
  .point_number {
    height: 29px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #292B2D;
  }
  
  .point_date {
    height: 19px;
    left: 337px;
    top: 102px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FD3C4A;
    margin-top: 3%;
  }
}


.card_info {
  margin-top: 16px;
  display: flex;
  align-items: 'start';
  width: 100%;
  background: #FFFFFF;
  border-radius: 14px;
  flex-direction: row;
  padding-top: 46.5px;
  padding-bottom: 46.5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
}
.info {
  justify-content: center !important;
  &__img{
    width:18px;
    height:18px;
    margin-right: 8.86px;
  }
  &__title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #1E4DB7;

  }
  &__name{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-top: 14.5px;
    margin-bottom: 8px;
  }
  &__sdt{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
  }
}
.title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.progress-point {
  margin-top: 16px;
  display: flex;
  align-items: 'start';
  width: 100%;
  background: #FFFFFF;
  border-radius: 14px;
  flex-direction: row;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 3.4%;
  padding-right: 3.4%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #202020;
  }
  .group-item {
    display: flex;
    width: 100%;
    padding-left: 9%;
    padding-right: 9%;
    margin-bottom: 24px;
    .img-group-item {
      justify-content: center;
      border-radius: 8px 8px 0 0 !important;
      width: 100%;
      max-width: 708px;
      margin-top: 48px;
      max-height: 80px;
    }
  }
  .group-item-text {
    display: flex;
    flex-direction: row;
    .group-price{ 
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #91919F;
      margin-bottom: 8px;

    }
    .group-title{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #000000;
    }
  }

  .card-background {
    border-radius: 14px; 
    margin-top: 23px;
    padding-top: 28px;
    padding-left: 48px;
    padding-bottom: 28px; 
    background: #F7F7F7;
    .card {
      display: flex;
      flex-direction: row;
      .avatar{
        width: 120px;
        height: 120px;
      }
      .span-text{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }
    }
  }
}
.title-auto-sync{
  font-weight: 500;
  font-size: 18px;
}
