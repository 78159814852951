.title {
    >span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #000000;
    }
}

.body {
    .body-item{
        display: flex; 
        flex-direction: column; 
        border-top: 1px solid #C4C4C4; 
        margin-top: 4px; 
        padding-top: 4px;
    }
}