@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-calendars/styles/material.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.card {
    box-shadow: 0 12px 23px 0 #5c37990a;
    border-radius: 10px;
    border: 0 solid transparent;
    overflow: hidden;
    background-color: white;
    margin: 12px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 80px);
}

.master-menu {
    margin-bottom: 10px !important;
    font-family: "Inter";
    font-weight: 500;
    font-size: 16px;
    color: #c8c8c8;
    text-transform: uppercase;
}

*,*:focus,*:hover{
    outline:none;
}

/* Edit Scrollbar */
::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #707070c9;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display: none;
}