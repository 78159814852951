.layout-otp {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  .layout-carousel {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: rgb(181, 181, 181);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .layout-otp-form {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .form-otp-responsive {
      width: 360px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.01em;
        color: #101828;
        flex: none;
        margin-bottom: 12px;
      }
      .custom-otp {
        width: 48px !important;
        height: 56px;
        background: #ffffff;
        border: 1px solid #7592E1;
        border-radius: 8px;
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #101828;
        &:focus {
          border: 1px solid #335BD3 !important;
          padding: 4px !important;
          box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 2px #E2E8F9 !important;
        }
      }
      .custom-otp-err {
        width: 48px !important;
        height: 56px;
        background: #ffffff;
        border: 1px solid #F04438 !important;
        border-radius: 8px;
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #101828;
        &:focus {
          border: 1px solid #F04438 !important;
          padding: 4px !important;
          box-shadow: 0 0 4px 4px #c9d4f3 !important;
        }
      }
      .custom-container-otp {
        gap: 8px;
      }
      .input-otp {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #101828;
      }
      .note {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #667085;
        flex: none;
        margin-bottom: 32px;
        > span {
          color: #101828;
          font-size: 14px;
          font-weight: 600;
        }
      }
      .count-length-otp {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #101828;
        flex: none;
        margin-bottom: 16px;
      }
      .note-2 {
        margin-bottom: 24px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #667085;
        gap: 4px;
        width: 400px;
        justify-content: center;
        > p > span {
          color: #335bd3;
          font-weight: 600;
          cursor: pointer;
        }
      }
      .link {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #2949a9;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .layout-otp {
    width: 100%;
    height: 100vh;
    .layout-carousel {
      display: none;
    }
  }
}

@media screen and (max-width: 450px) {
  .layout-otp {
    .layout-otp-form {
      .form-otp-responsive {
        .note-2 {
          flex-direction: column;
          width: unset;
        }
      }
    }
  }
}


@media screen and (max-width: 390px) {
  .layout-otp {
    .layout-otp-form {
      .form-otp-responsive {
        width: 310px;
        .custom-otp {
          width: 38px;
          height: 50px;
        }
        .custom-otp-err {
          width: 38px;
          height: 50px;
        }
        .custom-container-otp {
          gap: 4px;
        }
        .note-2 {
          flex-direction: column;
        }
      }
    }
  }
}
