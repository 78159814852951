.container{
    background-color: '#fff';
    height: 80px;
}

.title {
    font-family: initial;
    font-weight: bold;
    font-size: 40px;
    margin-left: 10%;
}