.cart-layout {
    background-color: white;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 2.5%;
    min-width: 800px;
    .cart-header-h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #1E4DB7;
        margin-bottom: 0;
        border-bottom: 1px solid #D8D8D8;
    }

}