.content-message {
    display: flex;
    flex-direction: row;
    height: 100%;
    .more-options{
        display: none ;
    }
    .display-message {
        display: flex;
        flex-direction: row;
        .content {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            .content-text{
                padding: 5px 14px;
                word-wrap: break-word; 
                max-width: 564px;
                transition: max-width 1s;
                .chat_text_2 {
                    color: gray
                }
            }
            .chat_time{
                font-size: 11px;
                color: #777;
            }
        }
    }
    &:hover {
        .more-options {
            display: flex !important;
            align-items: center;
            height: 100%;
            margin-right: 7px;
        }
    }
}



//wrap-text
@media (max-width: 1300px) {
    .content-message {
        .display-message {
                .content {
                    .wrap-text {
                        max-width: 500px;
                    }
                }
            }
    }
}


@media (max-width: 1200px) {
    .content-message {
        .display-message {
                .content {
                    .wrap-text {
                        max-width: 400px;
                    }
                }
            }
    }
}

@media (max-width: 1100px) {
    .content-message {
        .display-message {
                .content {
                    .wrap-text {
                        max-width: 300px;
                    }
                }
            }
    }
}

@media (max-width: 1000px) {
    .content-message {
        .display-message {
                .content {
                    .wrap-text {
                        max-width: 200px;
                    }
                }
            }
    }
}

@media (max-width: 900px) {
    .content-message {
        .display-message {
                .content {
                    .wrap-text {
                        max-width: 100px;
                    }
                }
            }
    }
}

//wrap-text-2

@media (max-width: 1549px) {
    .content-message {
        .display-message {
            .content {
                .wrap-text-2 {
                    max-width: 500px;
                }
            }
        }
    }
}

@media (max-width: 1449px) {
    .content-message {
        .display-message {
            .content {
                .wrap-text-2 {
                    max-width: 400px;
                }
            }
        }
    }
}

@media (max-width: 1349px) {
    .content-message {
        .display-message {
            .content {
                .wrap-text-2 {
                    max-width: 300px;
                }
            }
        }
    }
}

@media (max-width: 1249px) {
    .content-message {
        .display-message {
            .content {
                .wrap-text-2 {
                    max-width: 200px;
                }
            }
        }
    }
}

@media (max-width: 1149px) {
    .content-message {
        .display-message {
            .content {
                .wrap-text-2 {
                    max-width: 100px;
                }
            }
        }
    }
}