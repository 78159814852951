.text-blue {
  color: #1e4db7;
  font-size: inherit;
  font-size: 16px;
}

.history {
  padding: 0 33px;
  font-weight: 600;
  font-size: 16px;

  &__header {
    display: flex !important;
    justify-content: space-between;
  }

  &__main {
    font-size: inherit;
    margin-top: 5px;
  }

  &__order {
    display: flex !important;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #f3eaea;
  }

  &__para {
    color: #000;
    margin: 0;
  }

  &__btn-status {
    @extend .text-blue;
    align-self: flex-end;
    cursor: pointer;
  }

  &__id,
  &__transcode,
  &__price {
    @extend .text-blue;
  }
}
