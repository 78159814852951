.body_brand {
  background-color: white;
  padding: 16px;
  margin: 15px 15px;
  border-radius: 5px;
  width: 99%;
  height: 680px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.container {
  margin: 50px auto;
  width: 85%;
}

.title {
  display: flex;
  justify-content: space-between;
}

.heading-right {
  //border: 1.25px dashed #39CB7F;
  padding: 10px;
  width: 50em;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%2339CB7F' stroke-width='2' stroke-dasharray='8 8' stroke-dashoffset='86' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
}

.body_brand_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}

.body_brand_header_title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.body_brand_header_list_text {
  font-size: 1.2rem;
  font-weight: 600;
  padding-right: 10px;
}

.button_create_brand {
  background-color: #007bff;
  color: white;
}

.body_brand_filter {
  padding: 15px 0;
}

.body_brand_delete_filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}

.step2 {
  margin-top: 40px;
  text-align: center
}

.button {
  text-align: center;
}

.containerItem {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  margin: 10px;

}

.iTem {
  margin-left: auto;
  margin-right: auto;
}

//upload image
.containerItemUpload {
  margin-left: auto;
  margin-right: auto;
  // width: 40%;
  display: flex;
  // grid-template-columns: repeat(2, 1fr);
  // grid-gap: 24px;
  margin: 10px;

}

.iTemupload {
  margin-left: auto;
  margin-right: auto;
}

//
.dashboard_manager_bottom_row_col_parent_top > div {
  font-size: 1rem;
  font-weight: 600;
  color: black;
}

.dashboard_manager_bottom_row_col_parent_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(197, 184, 184);
}

.dashboard_manager {
  display: flex;
  flex-direction: column;
  margin: 12px;
  max-height: 100%;
}

.dashboard_manager_balance {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
  background-color: white;
  padding: 12px;
}

.card {
  box-shadow: 0 12px 23px 0 #5c37990a;
  border-radius: 10px;
  border: 0 solid transparent;
  overflow: hidden;
}

.dashboard_manager_balance_title {
  width: 100%;
  padding-left: 1rem;
  display: flex;
  border-bottom: 1px solid rgb(204, 189, 189);
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
}

.dashboard_manager_balance_title > div {
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: black;
}

.dashboard_manager_revenue_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(206, 192, 192);
}

.dashboard_manager_revenue_title > div {
  color: black;
  font-size: 1rem;
  font-weight: 600;
}

.dashboard_manager_bottom {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dashboard_manager_bottom_row_col_parent_top > div {
  font-size: 1rem;
  font-weight: 600;
  color: black;
}

.dashboard_manager_bottom_row_col_parent_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(197, 184, 184);
}

.card-overview {
  box-shadow: 0 12px 23px 0 #5c37990a;
  border-radius: 0 0 10px 10px;
  border: 0 solid transparent;
  overflow: hidden;
  background-color: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  height: auto;
  margin-bottom: 15px;
  width: 100%;
  margin-right: auto;
}

.steps-content {
  min-height: 200px;
  // margin-top: 16px;
  padding-top: 80px;
  text-align: center;
  background-color: #dc3636;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}

//opt
.otp-input {
  border-right: none !important;
  border-radius: 5px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.otp-button {
  position: absolute !important;
  left: calc(33% + 160px);
  top: 0;
  background: #00ccff !important;
  color: #fff !important;
  height: 32px;
  border: 1px solid #00ccff !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  min-width: 80px;
}

.resend {
  // margin-bottom: 0;
  cursor: pointer;
  font-weight: 700;
  margin-left: 3px;
  transition: all 0.5s;

  &:hover {
    color: rgb(146, 207, 243);
  }
}

.upload-image-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 178px;
  cursor: pointer;
}


.ekata__step-item.ant-steps-item-active {
  background: rgb(36, 209, 252) !important;
  margin-right: 8px;
  height: 40px;
}

.ekata__step-item:first-child::after {
  border-left: none !important;
}

.ekata__step-item:first-child {
  border-top-left-radius: 10px !important;
}

.ekata__step-item:last-child {
  margin-right: 0 !important;
}

.ekata__step-item:last-child {
  border-top-right-radius: 8px !important;
}

.ekata__step-item::before {
  content: "" !important;
  position: absolute !important;
  right: -20px !important;
  left: auto !important;
  bottom: 0 !important;
  width: 0 !important;
  height: 0 !important;
  background-color: transparent !important;
  border-left: 20px solid rgb(36, 209, 252) !important;
  border-top: 20px solid transparent !important;
  border-bottom: 20px solid transparent !important;
  z-index: 1 !important;

}

.ekata__step-item::after,
.ekata__step-item:last-child::before {
  content: "" !important;
  position: absolute !important;
  left: 0 !important;
  top: auto !important;
  bottom: 0 !important;
  width: 0 !important;
  height: 0 !important;
  transform: none !important;
  border: none !important;
  border-left: 20px solid #f0f2f5 !important;
  border-top: 20px solid transparent !important;
  border-bottom: 20px solid transparent !important;
  z-index: 0 !important;
}

.ekata__step-item .ant-steps-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.ekata__step-navigation {
  margin-top: 40px !important;
}

.ant-upload-select {
  width: 100% !important;
  height: 176px !important;
}

.result {
  width: 100%;
  height: 600px;
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  padding-top: 81px
}

.register-business-btn {
  margin-right: 10px !important;
  min-width: 150px !important;
  height: 50px !important;
  background: #1e4db7 !important;
  font-weight: bold !important;
  border: none !important;
  border-radius: 7px !important;

  &:hover {
    background: #123b94 !important;
  }

  &:focus {
    background: #123b94 !important;
  }

  &:active {
    background: #123b94 !important;
  }
}

.visit-business-btn {
  margin-right: 10px !important;
  min-width: 150px !important;
  height: 50px !important;
  background: #03a9f4 !important;
  font-weight: bold !important;
  border: none !important;
  border-radius: 7px !important;

  &:hover {
    background: #00b2ff !important;
  }
  &:focus {
    background: #00b2ff !important;
  }
  &:active {
    background: #00b2ff !important;
  }
}

.delete-business-btn {
  margin-right: 10px !important;
  min-width: 150px !important;
  height: 50px !important;
  background: #f53333 !important;
  font-weight: bold !important;
  border: none !important;
  border-radius: 7px !important;

  &:hover {
    background: #f53333 !important;
  }
  &:focus {
    background: #f53333 !important;
  }
  &:active {
    background: #f53333 !important;
  }
}
.cancel-business-btn {
  margin-right: 10px !important;
  width: 150px !important;
  height: 50px !important;
  background: #F7F7F7 !important;
  color: #6C757D !important;
  font-weight: bold !important;
  border: none !important;
  border-radius: 7px !important
}