.title-discount{
    > span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #656565;
    }
    width: 400px;
}
.content-discount{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 400px;
    justify-content: space-between;
    .span-1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #656565;
    }
}