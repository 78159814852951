.card__list-empty {
  font-size: 16px;
  text-align: center;
}

.card-subscriber {
  display: flex;

  &__title {
    color: #202020;
    font-weight: 600;
    font-size: 16px;
  }

  &__meta {
    align-items: center;
    width: 15em;
  }

  &__description {
    font-size: 14px;
  }

  &__avatar {
    border-radius: 50% !important;

    &-box {
      position: relative;
    }

    &-status {
      position: absolute;
      right: -5px;
      bottom: 0;
      z-index: 1;
      background: #38e25d;
      border: 3px solid #fff;
      border-radius: 50%;
      height: 19px;
      width: 19px;
    }
  }

  &-feeds {
    padding: 0 !important;
    display: flex !important;
    justify-content: space-between !important;
    padding: 0 33px 30px 33px !important;
  }

  &-bussiness {
    column-gap: 19px !important;
    justify-content: space-between !important;
    flex-wrap: wrap !important;

    & > * {
      width: 70% !important;
    }

    & > div:last-child {
      width: auto !important;
    }

    &__description {
      color: #202020 !important;
      line-height: 17px !important;
      font-weight: 400 !important;
      font-size: 14px !important;
    }
  }
}

.card-shopping-history {
  &__avatar {
    border-radius: 6px;
  }

  &__title {
    color: #1e4db7;
    font-weight: 400;
    font-size: 14px;
  }

  &__description {
    color: #202020 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    font-weight: 600 !important;
  }
}
