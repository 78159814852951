.type {
    margin-left: 15px;
    margin-top: 15px;
    > span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
    }
}

.header{
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    .child-text {
        display: flex;
        flex-direction: column;
        text-align: start;
        .span-1 {
            font-size: 15px;
            font-weight: 600;
            font-family: 'Inter';
            cursor: pointer;
        }
        .span-2 {
            font-size: 10px;
            font-weight: 400;
            font-family: 'Inter';
            color: #A8A8A8;
        }
    }
}

.body {
    width: 100%;
    .more-option {
        margin-top: 20px;
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        .span-1 {
            cursor: pointer;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 19px;
            color: #313131;
        }
        .span-2 {
            cursor: pointer;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            color: #313131;
            margin-left: 15px;
            margin-top: 7px;
        }
    }

    .more-option-2 {
        margin-top: 15px;
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        .span-1 {
            cursor: pointer;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 19px;
            color: #313131;
        }
        .span-2 {
            cursor: pointer;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            color: #313131;
            margin-left: 15px;
            margin-top: 7px;
        }
    }

    .more-option-3 {
        margin-top: 15px;
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        .span-1 {
            cursor: pointer;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 19px;
            color: #313131;
        }
        .span-2 {
            cursor: pointer;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            color: #313131;
            margin-left: 15px;
            margin-top: 7px;
        }
        .span-3 {
            cursor: pointer;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            color: #FF5555;
            margin-left: 15px;
            margin-top: 7px;
        }
    }
}