.layout-item {
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    .layout-item-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 290px;
        .child-text {
            display: flex;
            flex-direction: column;
            .span-1 {
                font-size: 14px;
                font-weight: 400;
                font-family: 'Inter';
            }
            .span-2 {
                font-size: 14px;
                font-weight: 400;
                font-family: 'Inter';
                color: #A8A8A8;
            }
        }
    }
    .container {
        display: block;
        position: relative;
        margin-bottom: 22px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        > input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
          .checkmark {
            position: absolute;
            border-radius: 50%;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #eee;
          }
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }
      }
      .container:hover input ~ .checkmark {
        background-color: #ccc;
      }
      
      .container input:checked ~ .checkmark {
        background-color: #2196F3;
      }
      
    
      .container input:checked ~ .checkmark:after {
        display: block;
      }
      
      .container .checkmark:after {
        left: 10px;
        top: 6px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
}

.item :hover {
    background-color: #f0f0f0;
    cursor: pointer;
}