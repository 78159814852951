.label-style {
  width: 89px;
  height: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 0;
  width: 100%;
}