.icon {
  &__dot {
    background: #a5a5a5;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    list-style-type: none;
    display: inline-block;
  }
  &__dot:not(:first-child) {
    margin-left: 3px;
  }
}
