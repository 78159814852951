.title {
    width: 100%;
    display: flex;
    justify-content: center;
    > span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #444444;
    }
}
.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    .information {
        display: flex;
        flex-direction: column;
        margin-left: 19px;
        > span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            color: #000000;
        }
    }

}

.body {
    margin-top: 20px;
    .body-content {
        > textarea {
            width: 100%;
            min-height: 70px;
            border: none;
            outline: none;
            resize: none;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #4A4A4A;
        }
    }
    .body-footer {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid #BABABA;
        border-radius: 10px;
        padding: 12px;
        > span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            color: #5C5C5C;
        }
    }
    .body-media {
        display: flex;
        flex-direction: row;
        padding: 15px;
        overflow-x: scroll;
    }
}

.footer {
    width: 100%;
    margin-top: 9px;
    .footer-span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;
    }
}

