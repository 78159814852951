.margin-center {
  margin-top: auto;
  margin-bottom: auto;
}
.display-flex {
  display: flex;
}

.container-top10 {
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 4px 4px rgb(0 0 0 / 4%);
  border-radius: 14px;
  height: fit-content;
  width: calc(100% - 65%);
  margin-top: 90px;
  margin-left: 10px;
}

.dashboard_manager_bottom_row_col_parent_top {
  display: flex;
  border-bottom: unset !important;
  align-content: center;
  align-items: center;
  padding-bottom: unset;
  height: 70px;
  color: #f3eeee;

  div {
    font-size: 16px;
    font-weight: bold;
    color: #c5c5c5;
    margin-left: 20px;
  }
}

.nav-top {
  background: url('../../assets/img/bg1.jpg');
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  // margin: 20px;
  margin-bottom: 16px;

  border-radius: 15px;
}
.container-left {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 80px;
  display: flex;
}
.avatar {
  background-color: #fff;
}
.content-avt {
  color: #fff;
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 32px;
  line-height: 50%;
}
.container-right {
  display: inline-block;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 60%;
}

.container-account {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  margin: 10px;
}
.top-right-avt {
  text-align: end;
}
.container-dh {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  margin: 20px;
}
.center {
  text-align: center;
}
.card {
  // box-shadow: 0 12px 23px 0 #5c37990a;
  border-radius: 10px;
  border: 0 solid transparent;
  overflow: hidden;
}

.dashboard_manager_balance_title {
  width: 100%;
  padding-left: 1rem;
  display: flex;
  border-bottom: 1px solid rgb(204, 189, 189);
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1rem;
}
.dashboard_manager_balance_title > div {
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: black;
}

.dashboard_manager_revenue_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
}
.dashboard_manager_revenue_title > div {
  color: black;
  font-size: 1rem;
  font-weight: 600;
}
.dashboard_manager_bottom {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dashboard_manager_bottom_row_col_parent_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(197, 184, 184);
}

.card-overview {
  border-radius: 10px;
  border: 0 solid transparent;
  overflow: hidden;
  background-color: white;
  padding: 12px;
  display: flex;
  flex-direction: column;
}
.container-tabs {
  background: #f5f5f5 !important;
  width: 97%;

  &__topbusiness {
    margin-top: 91px;
    align-self: flex-start;
  }

  &__newfeeds {
    max-width: 683px;
    width: calc(100% - 20px - 36.5%);
  }
}

.tabpane {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  padding: 0 30px;
  width: 50%;
}

.container-button-content {
  margin-left: auto;
  margin-right: auto;
}
.content-top {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}
