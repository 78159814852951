.profile {
  max-width: 320px;

  &__avatar-box {
    position: relative;
  }

  &__bg-avatar {
    border-radius: 8px 8px 0 0 !important;
    width: 100%;
    height: 120px;
    object-fit: contain;
  }

  &__avatar {
    width: 105px;
    height: 104px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50% !important;
  }

  &__settings {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  &__title {
    white-space: initial;
    margin-top: 40px;
  }

  &__name {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  &__status {
    color: #6c757d;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }

  &__item {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  &__item-text {
    margin: 0 !important;
    font-size: 14px;
    font-weight: 600;
  }

  &__item-number {
    color: #1e4db7;
  }
  &__media {
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
  }
  &__follow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__follow-number {
    font-size: 18px;
    color: #292b2d;
  }

  &__follow-text {
    color: #6c757d;
    font-size: 12px;
    margin-top: -5px;
  }

  &__statistic {
    margin-top: 15px;
  }

  &__item:not(:first-child) {
    margin-top: 5px;
  }

  &__item-text,
  &__item-icon {
    color: #292b2d;
    font-size: 14px;
    font-weight: 400;
  }

  &__item-number {
    color: #1e4db7;
    font-weight: 600;
  }
}
