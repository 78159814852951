.display-flex {
  display: flex;
}

.container-layout-setting {
  width: 80%;
  // width: 1123px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  border-radius: 7px;
}

.ant-upload-select > div {
  height: 186px !important;
  background: #cdcdcd !important;
  border-radius: 18px !important;
}
.text-upload {
  font-style: normal;
  margin-top: 8px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;
}
.dashboard_manager_bottom_row_col_parent_top {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(197, 184, 184);
}
.font-bold {
  margin-bottom: 43px;
  margin-top: 62px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}
.card-overview {
  box-shadow: 0 12px 23px 0 #5c37990a;
  border-radius: 10px;
  border: 0 solid transparent;
  overflow: hidden;
  background-color: white;
  margin: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
}
.container-accuracy {
  width: 1123px;
  padding: 36px;
  margin-bottom: 30px;
}

.container {
  width: 100%;
  margin-bottom: 30px;
  margin-top: 72px;
  display: flex;
  flex-direction: column;
}
.width-input {
  width: 50%;
}
.note {
  margin-left: 16%;
}
.button-push {
  margin: 0px 8px;
  width: 120px;
}
.container-guide {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.container-item {
  width: 448px;
  padding: 4px;
}
.title-item {
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 7px;
}
.content-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0.3em !important;
}
.width-input-xt {
  width: 400px;
}
.width-input-xt > span {
  background: #ccc;
}
.meta-name {
  max-width: 300px;
  height: 35px;
  left: 166.73px;
  top: 19.59px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-top: 10px;
  margin-right: 10px;
  color: #202020;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
//antd
.margin-bottom {
  margin-bottom: 0 !important;
}

.content {
  width: 321px;

  margin-left: 18%;
  margin-top: -16px;
}
.container-node {
  width: 35%;
  margin-right: 17px;
}
.node-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  margin-bottom: 7px;
  text-align: right;

  color: #000000;
}
.node-describe {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: right;

  /* Base/Light/Light 20 */

  color: #91919f;
}
.container-input{
  margin-left: 74px;
  margin-top: 24px;
}
.container-icon-image{
  width: 54px !important;
  height: 38px !important;
  border-radius: 0 !important;
}
.avatar {
  background-repeat: no-repeat;
  background-position: center ;
  background-size: cover;
  // border: 0.1875em solid #0F1C3F;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: flex-end;
}

.edit_avatar {
  width: 77px;
  height: 34px;
  background-color: #7e7e7e;
  border-bottom-left-radius: 55px;
  border-bottom-right-radius: 55px;
  margin-top: 50px;
  // transform: rotate(180deg);
  opacity: 0.4;

  border-bottom: 0;
  text-align: center;
  color: aliceblue;
  font-size: 20px;
  margin-left: 2px;
}
.edit_avatar:hover{
  opacity: 0.7;
  cursor : pointer;
}