.cart-shop {
    background: #FFFFFF;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    margin-top: 11px;
    width: 100%;
    .header {
        display: flex;
        flex-direction: row;
        padding: 1.3% 3% 1.3% 3%;
        border-bottom: 1px solid #D8D8D8;
        .item-1 {
            display: flex;
            align-items: center;
            width: 16px;
            margin-right: 2%;
            cursor: pointer;
            }
        .item-2 {
            cursor: pointer;
            margin-right: 1%;
        }
        .item-3 {
            margin-right: 1%;
            cursor: pointer;
            > span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                display: flex;
                align-items: center;
                color: #000000;
            }
        }
        .item-4 {
            margin-right: 1.8%;
            cursor: pointer;
        }

        .item-5 {
            display: flex;
            align-items: center;
            cursor: pointer;
            > span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;

                text-align: center;
                color: #0BB2FB;
            }
        }
    }
        
        
    .body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;   
        padding: 1.3% 3% 1.3% 3%;
        .item-1 {
            width: 16px;
            margin-right: 2%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .item-2 {
            width: 50%;
            margin-right: 1%;
            display: flex;
            flex-direction: row;
            align-items: center;
            > div > span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #000000;
            }
        }
        .item-3 {
            width: 10%;
            margin-right: 1%;
            min-width: 110px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .span-1 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #000000;
            }
            .span-2 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #818181;
                text-decoration-line: line-through
            }
        }
        .item-4 {
            width: 10%;
            margin-right: 1%;
            min-width: 110px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .item-5 {
            width: 10%;
            margin-right: 1%;
            min-width: 110px;
            display: flex;
            justify-content: center;
            align-items: center;
            > span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #1E4DB7;
            }
        }
        .item-6 {
            width: 10%;
            min-width: 110px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .footer {
        display: flex;
        flex-direction: row;
        padding: 1.3% 3% 1.3% 3%;
        border-top: 1px solid #D8D8D8;
        .item-2 {
            > span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                display: flex;
                align-items: center;
                color: #1E4DB7;
            }
        }
    }

}