.header-status {
    padding-left: 4%;
    padding-right: 4%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: end;
    .span-0 {
        width: 235px;
        height: 29px;
        left: 59.61px;
        top: 52px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #202020;
    }
    .span-1 {
        width: 245px;
        height: 19px;
        left: 401.6px;
        top: 61px;
        font-family: 'Inter';
        font-weight: 500;
        font-size: 16px;
        line-height: 19.36px;
        color: #000000;
        text-align: end;
        .span-1-0 {
            border-left: 1.15px solid #D9D9D9;
            padding-left: 7.24px;
            margin-left: 5.94px;
            width: 245px;
            height: 19px;
            left: 401.6px;
            top: 61px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19.36px;
            text-transform: uppercase;
            color: #0BB2FB;
        }  
    }
}

.tab-status {
    padding: 4%;
    .tab-status-text{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: center;
        .p-0 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #000000;
            margin-bottom: 9.5px;
            margin-top: 28.74px;
        }
        .p-1 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #91919F;
        }
        .tab-status-divider {
            border-top: 3.29px solid #39CB7F;
            padding-bottom: 3px;
            padding-top: 3px;
            width: 21%;
            margin-top: 30px;
            margin-left: 65px;
            color: white;
            position: absolute;
        }
        .tab-status-divider-2 {
            border-top: 3.29px solid #E3E5E5;
            padding-bottom: 3px;
            padding-top: 3px;
            width: 21%;
            margin-top: 30px;
            margin-left: 65px;
            color: white;
            position: absolute;
        }
    }

}

.check-status {
    padding: 4%;
    width: '100%';
    height: 107.01px;
    left: 0px;
    top: 368.75px;
    background: #F7F7F7;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .span-0 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #6C757D;
    }
    .span-1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
    }
}

.delivery-status {
    display: flex;
    flex-direction: row;
    padding: 4%;
    
    .delivery-information{
        width: 45%;
        margin-right: 2%;
        .p-0 {
            height: 22px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #202020;
            margin-bottom: 14.8px;
        }
        .p-1 {
            height: 19px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            margin-bottom: 10.45px;
        }
        .p-2 {
            height: 19px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #91919F;
            margin-bottom: 10.45px;
        }
        .p-3 {
            height: 19px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #91919F;
        }
    }

    .delivery-shipping {
        width: 55%;
        border-left: 0.71px solid #D9D9D9;

        .status-shipping {   
            margin-left: 5%;
            align-items: center;
            display: flex;
            text-align: center;
            .span-line {
                padding-bottom: 3px;
                padding-top: 3px;
                position: absolute;
                text-indent: -9999px;
                margin-left: 3px;
            }
            .span-dot {
                height: 7px;
                width: 7px;
                border-radius: 50%;
                display: inline-block;
                padding-bottom: 3px;
                padding-top: 3px;
            }
            .span-0 {
                margin-left: 1.2%;
                top: 0%;
                bottom: 0%;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                color: #292B2D;
                padding-bottom: 3px;
                padding-top: 3px;

            }
            .span-1 {
                margin-left: 1%;
                top: 0%;
                bottom: 0%;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                color: #1E4DB7;
                padding-bottom: 3px;
                padding-top: 3px;
            }
        }
    }

}
