.items {
    .item-main{
        padding: 7px;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        .img-voucher {
            width: 70px;   
            height: 70px;
            object-fit: contain;   
            margin-left: 12px;
        }
        .item-body{
            margin-left: 15px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            color: #281616;
            width: 100%;
        }
    }
}


.items :hover {
    background-color: #F8F8F8;
}
