.layout-forget-password {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  .layout-carousel {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: rgb(181, 181, 181);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .layout-forget-password-form {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .form-forget-password-responsive {
      width: 360px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.01em;
        color: #101828;
        margin-bottom: 12px;
      }
      .note {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #667085;
      }
      .label-style {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
        margin-bottom: 0px;
      }
      .link {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #2949a9;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .layout-forget-password {
    width: 100%;
    height: 100vh;
    .layout-carousel {
      display: none;
    }
  }
}

@media screen and (max-width: 390px) {
  .layout-forget-password {
    .layout-forget-password-form {
      .form-forget-password-responsive {
        width: 310px;
      }
    }
  }
}
