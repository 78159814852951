.container{
    align-items: center;
    background-color: #ffffff;
}
.title{
    background: #f8fafd;
    padding: 170px 0 70px;
    // position: relative;
    z-index: 1;

    .name{
        font-size: 48px;
        font-weight: bold;
        font-family: inherit;
        text-align: center;
        color: #000000;
    }
}

.logo{
    top: 0;
    width: 100%;
    margin: 0 150px 0 150px;
    
    }
.header{
    width: 100%;
    background-color: #ffffff;
    display: block;
    position: fixed;
    padding: 10px 0 23px 20px;
    height: 85px;
}
.header.sticky{
    background-color: blue;
    position: sticky;
    opacity: 0.9;
}
.body{
    padding: 100px 0;
    margin: 0 150px 0 150px;
    background-color:#ffffff;
    .strong{
        line-height: 1.7;
        font-size: 16px;
        height: 100%;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        color: #000000;

    }
    .item{
        font-family: inherit;
        font-size: 27px;
        font-weight: 700;
        color: #000000;
    }
    .content{
        font-size: 16px;
    font-family: inherit;
    font-weight: 400;
    color: #000000;
}
}
@media only screen and (max-width: 992px) {
    .body {
        margin: 0 50px 0 50px;
    }
    .logo{
        margin: 0 50px 0 50px;
    }
  }
  @media only screen and (max-width: 600px) {
    .body {
        margin: 0 10px 0 10px;
    }
    .logo{
        margin: 0 10px 0 10px;
    }
  }