.right-side {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    .chat-info {
        width: calc(100% - 249px);
        .header {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #DBDBDB;
            width: 100%;
            padding: 0 20px;
            .layout-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                .child-text {
                    display: flex;
                    flex-direction: column;
                    text-align: start;
                    height: 100%;
                    .span-1 {
                        font-size: 15px;
                        font-weight: 600;
                        font-family: 'Inter';
                        cursor: pointer;
                    }
                    .span-2 {
                        font-size: 10px;
                        font-weight: 400;
                        font-family: 'Inter';
                        color: #A8A8A8;
                    }
                }
            }
        }
        .body {
            width: 100%;
            overflow-y: auto;
            padding: 0 10px;
            height: calc(100% - 120px);
            .chat_display{
                width: 100%;
                min-height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
            .chat_row{
                display: grid;
                grid-template-columns: 70%;
                margin-bottom: 10px;
            }
            .you_message{
                justify-content: end;
                justify-items: end;
            }
            .other_message{
                justify-content: start;
                justify-items: start;
            }
    
    
        }
    
        .show_media{
            display: flex;
            flex-direction: row;
            overflow-x: scroll;
            width: 100%;
            border-top: 1px solid #DBDBDB;
            border-bottom: 1px solid #DBDBDB;
            padding: 5px;
            padding-top: 10px;
        }
    
        .footer {
            padding: 10px 10px;
    
            .chat_input {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid #DBDBDB;
                border-radius: 50px;
                padding: 0 15px;
    
                .file_upload {
                    position: relative;
                    overflow: hidden;
                    margin: 0 10px;
                }
                > input {
                    width: 100%;
                    height: 39px;
                    border: none;
                    outline: none;
                    margin-left: 7px;
                    margin-right: 7px;
                }
    
                > button {
                    border: none;
                    outline: none;
                    background: white;
                }
            }
        }
    }


    .more-detail {
        width: 249px;
        height: 100%;
        border-left: 1px solid #DBDBDB;
    }
}