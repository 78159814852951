.full-side {
    display: flex;
    flex-direction: row;
    height: calc(100%);

    .left-side {
        width: calc(100% - 400px);
        height: 100%;
        background-color: black;
        display: flex;
        flex-direction: row;
        .item-left{
            width: 10%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .item-middle{
            width: 80%;
            height: 100%;
            .carousel-style {
                .item-div-img {
                    height: calc(100vh - 72px) !important;
                    display: flex !important;
                    justify-content: center !important;
                    align-items: center !important;
                    .item-img {
                        max-height: 100% !important;
                        max-width: 75%;
                        object-fit: cover;     
                    }
                }
            }

        }
        .item-right{
            width: 10%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    
    .right-side {
        width: 400px;
        height: 100%;
        position: fixed;
        right: 0;

        background-color: white;
        .info {
            display: flex;
            flex-direction: column;
            .span-1 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #000000;
            }
            .span-2 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #7A7A7A;
            }
        }
        .scroll-body {
            overflow: scroll;
            height: calc(100% - 250px );

        }
        .scroll-body::-webkit-scrollbar {
            display: none !important;
            overflow: hidden !important;
        }
        .info-1 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #0BB2FB;
            margin-bottom: 5px;
            > span {
                color: #6C757D;
            }
        }
    }
}
