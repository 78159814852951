.button-prev {
  position: absolute;
  bottom: 15px;
  left: 69.26px;
  z-index: 100;
}

.button-next {
  position: absolute;
  bottom: 15px;
  right: 69.26px;
  z-index: 100;
}

.carousel-styled {
  height: 596px;
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #fcfcfd;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 0;
    margin-top: 20px;
  }
  ul > li > button {
    border-radius: 50% !important;
    width: 10px !important;
    height: 10px !important;
  }
  ul > li:not(:empty) {
    width: 16px !important;
  }
}
