@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.user_information {
  display: flex;
  background-color: #fff;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 13px;
  border: 1px solid rgb(230, 218, 218);
  padding-bottom: 0.25rem;
  width: 15rem;
  border-radius: 15px;
  flex-direction: column;
  color: #565656;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.user_information > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.75rem 0;
  width: 100%;
  font-weight: 600;
}

.edit-submenu-arrow {
  color: rgba(0, 0, 0, 0.85);
}

.edit-submenu-arrow:hover {
  a {
    color: #5f73e2 !important;
  }
}

.header-row {
  height: 72px;
  padding: 0 15px;
  .header-search{
    height: 38.42px;
    width: 277.41px;
    margin-left: 22.74px;
    border-radius: 20px !important;
    background-color: #FAFAFA;
    input {
      background-color: #FAFAFA !important;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 21px;
    }
  }
}

.logo-block {
  display: flex;
  align-items: center;
  width: 70%;

  & img:last-child {
    margin: 0 10px;
  }
}

.sidebar {
  box-shadow: 18px 4px 35px rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: 18px 4px 35px rgba(0, 0, 0, 0.02);
  -moz-box-shadow: 18px 4px 35px rgba(0, 0, 0, 0.02);
  .filter-menu {
    .filter-items {
      .span-0 {
        margin-left: 40px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #6C757D;
      }
      .check-box {
        margin-left: 60px;
        margin-top: 10px;
        .span-option {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #6C757D;
        }
      }
      .range-price {
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        margin-top: 19.23px;
        justify-content: space-around;
      }
      .range-rate {
        margin-left: 20px;
      }
    }
  }

}
.cart-box {
    background-color: white;
    border: solid 1px rgb(241, 233, 233);
    border-radius: 15px;
    margin-top: 13px;
    width: 25rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .title {
      font-size: 16px;
      color: #565656;
      padding: 5px;
      font-weight: 600;
      border-bottom: solid 1px rgb(241, 233, 233);
      display: flex;
      justify-content: space-between;
    }
  
    .content {
      padding-top: 5px;
    }
}

.sidebar-menu {
  margin-top: 0px !important;
  font-family: 'Inter';
  a,
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
  }

  a:hover {
    color: #1e4db7 !important;
  }
}

.menu-icon {
  font-size: 23px;
}

.notify-icon {
  font-size: 24px;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 15px;
  font-family: 'Inter';
  font-style: normal;

  & span {
    width: 140px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & span:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 12.2285px;
    line-height: 15px;
  }
}

.new-business-block {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.new-business-btn {
  height: 40px !important;
  font-size: 17px !important;
  background: #0bb2fb !important;
  border-radius: 7px !important;
  border: none !important;
  &:hover {
    background: #0ba2e3 !important;
  }
}

.business-list {
  display: flex;
  flex-direction: column;
}

.business-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  margin-bottom: 10px;
}

.business-logo {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 50%;
}

.business-name {
  font-family: 'Inter';
  color: #6c757d;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #1e4db7;
  }
}

.show-more-business {
  cursor: pointer;
  background-color: #0BB2FB !important;
  border-color: #0BB2FB !important;
  color: white !important;
}
