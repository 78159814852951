.post {
    background-color: white;
    margin-top: 23px;
    margin-bottom: 23px;
    border: 1px solid #D2D2D2;
    border-radius: 10px;
    .header {
        display: flex;
        flex-direction: row;
        padding: 14px;
        justify-content: space-between;
        .info {
            display: flex;
            flex-direction: column;
            .span-1 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #000000;
                cursor: pointer;
            }
            .span-2 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #7A7A7A;
            }
        }
        .more-option :hover {
            background-color: rgb(221, 221, 221);
            border-radius: 50%;
            cursor: pointer;
        }
    }
    .body {
        .title {
            padding: 0 14px;
        }
    }
    .footer {
        .info {
            padding: 0 14px 0 14px;
            margin: 9px 0 9px 0px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .info-1 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #0BB2FB;
                > span {
                    color: #6C757D;
                }
            }
            .info-2 {
                > span {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    text-align: right;
                    color: #6C757D;
                }
            }
        }

    }
}
.icons {
    font-size: 32px;
    color: #6c757d;
    // background: #6C757D;
    .icon {
        margin-right: 15px;
        cursor: pointer;
      }
  }
.item-edit {
    cursor: pointer;
    >span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #000000;
        display: flex;
        align-items: center;
        height: 20px;
    }
}