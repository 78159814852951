.status {
    border-radius: 10px;
    background: white;
    border: 1px solid #DBDBDB;
    padding: 18px;
    .status-header {
        display: flex;
        flex-direction: row;
        .status-avatar {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-right: 12px;
        }
        .status-info {
            width: 90%;
            background-color: #E7E7E7;
            border-radius: 70px;
            padding: 14px;
            cursor: pointer;
        }
    }
    .status-media {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .media-button {
            width: 48%;
            display: flex;
            justify-content: center;
            background: #E6E6E6;
            border-radius: 10px;
            padding: 5px;
            cursor: pointer;
            > span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #4473B3;
            }
        }
    }
}