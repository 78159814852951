.dropdown-language {
  border: 1px solid #e7e7e7;
  padding: 0 5px 0 10px;
  border-radius: 20px;
  height: 40px;
  width: 150px;
}

.language-selected {
  color: black;
  width: 100%;
  margin-left: 7px;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
}
