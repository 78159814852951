.header-search{
    height: 38.42px;
    width: 277.41px;
    margin-left: 22.74px;
    border-radius: 20px !important;
    background-color: #FAFAFA;
    input {
      background-color: #FAFAFA !important;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 21px;
    }
  }
  .business-logo {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    border-radius: 50%;
  }
  .title{
    font-size: 14px;
    font-weight: 600;
    font-family: inherit;
    line-height: 18px;
    text-align: center;
    color: #000000
  }