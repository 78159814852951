.box-post {
    border-radius: 10px;
    background-color: rgb(224, 224, 224);
    padding: 10px;
    .info {
        display: flex;
        flex-direction: column;
        .span-1 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
            cursor: pointer;
        }
        .span-2 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #7A7A7A;
        }
    }
}

.box-message {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    > textarea {
        width: 100%;
        height: 100%;
        max-height: 150px;
        border: none;
        outline: none;
        resize: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: #4A4A4A;
    }
}

.box-search {
    border-radius: 50px;
    background-color: rgb(240, 240, 240);
    padding: 10px;
    margin-top: 15px;
    margin-right: 10px;
    width: calc(100% - 55.14px);
    >input {
        background-color: rgb(240, 240, 240);
        color: #4A4A4A;
        font-size: 16px;
        font-weight: 700;
    }
}

.box-icon-search {
    border-radius: 50%;
    background-color: rgb(240, 240, 240);
    margin-top: 15px;
    width: 45.14px;
    height: 45.14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.box-user {
    max-height: 227px;
    overflow: scroll;
    .info {
        display: flex;
        flex-direction: row;
        align-items: center;
        .span-1 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
            cursor: pointer;
        }
        .span-2 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #7A7A7A;
        }
    }
}