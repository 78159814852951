.cart-footer-1 {
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D8D8D8;
    .item-1 {
        width: 16px;
        margin-right: 2%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .item-2 {
        margin-right: 1%;
        > span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #000000;
        }
    }
    .item-3 {
        margin-right: 1%;
        min-width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        > span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            color: #6A6A6A;
        }
    }
    .item-4 {
        margin-right: 1%;
        min-width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.cart-footer-2 {
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.3% 3% 1.3% 3%;
    width: 100%;
    > div > span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        display: flex;
        align-items: center;
        color: #000000;
    }
    .item-1 {
        width: 16px;
        margin-right: 2%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .item-2 {
        margin-right: 1%;
        display: flex;
        justify-content: center;
        align-items: center;
        > span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            color: #000000;
        }
    }
    .item-3 {
        margin-right: 1%;
        min-width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        > span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            color: #FF2C2C;
        }
    }
    .item-4 {
        margin-right: 1%;
        min-width: 110px;
        display: flex;
        align-items: end;
        flex-direction: column;
        .span-1 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            color: #000000;
        }
        .span-2 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            color: #1E4DB7;
        }
    }
    .item-5 {
        margin-right: 1%;
        min-width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .item-6 {
        min-width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}