.layout-register {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;

  .layout-carousel {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: rgb(181, 181, 181);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .layout-form-register {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.form-register-responsive {
  width: 360px;
}

@media screen and (max-width: 992px) {
  .layout-register {
    width: 100%;
    height: 100vh;
    .layout-carousel {
      display: none;
    }
  }
}

@media screen and (max-width: 375px) {
  .form-register-responsive {
    width: 310px;
  }
}
