.label-style {
  width: 89px;
  height: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 0;
  width: 100%;
}
.another-frame {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  .checkbox-style {
    width: 157px;
    height: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    flex: none;
    order: 1;
    margin-bottom: 0;
  }
  .link-style {
    width: 102px;
    height: 20px;   
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2949a9;
    flex: none;
    order: 0;
    text-align: end;
    margin-bottom: 0;
  }
}
