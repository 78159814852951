.left-side {
    width: 350px;
    background-color: white;
    border: 1px solid #DBDBDB;
    height: 100%;
    border-radius: 4px 0 0 4px;
    .header {
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        height: 60px;
        border-bottom: 1px solid #DBDBDB;

        .header-name {
            width: 279px;
            display: flex;
            justify-content: center;
            align-items: center;
            > span {
                font-size: 16px;
                font-weight: 600;
                font-family: 'Inter';
            }
        }
        .header-icon {
            width: 31px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 27px;
            font-weight: 600;
            font-family: 'Inter';
        }
    }
    .body {
        overflow-y: auto;
        height: calc(100% - 60px);
        .layout-item {
            height: 72px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 20px;
            .child-text {
                display: flex;
                flex-direction: column;
                .span-1 {
                    font-size: 14px;
                    font-weight: 600;
                    font-family: 'Inter';
                }
                .span-2 {
                    font-size: 14px;
                    font-weight: 400;
                    font-family: 'Inter';
                    color: #A8A8A8;
                }
            }
        }
    }
}