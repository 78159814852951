.features{
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    font-size: 20px;
    font-weight: 400;
}
.text{
    font-size: 50px;
    text-align: center;
    font-weight: 600;
}