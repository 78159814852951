.card-voucher{
    min-width: 50px;
    min-height: 50px;
    border-radius: 10px;
    transition: all .3s ease-in;
    max-height: 150px;
    width: 450px;
    cursor: pointer;
    .img-voucher {
        width: 100%;
        height: 100%;
        max-height: 110px;
        min-width: 50px;
        min-height: 50px;
        border-radius: 10px;
        object-fit: contain;
      }
    .tag-code{
      border-radius: 4px;
      background: #F7F7F7;
      color: #6C757D;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      flex: none;
      order: 0;
      flex-grow: 0;
      padding: 1px 3px;
    }

}

@media (max-width: 1600px) {
        .card-voucher {
          width: 380px;
        }
      }
@media (max-width: 1400px) {
    .card-voucher {
    width: 340px;
    .tag-money {
      margin: 0 !important;
    }
}
}
@media (max-width: 1300px) {
  .card-voucher {
    width: 300px;
    .tag-money {
      margin: 0 !important;
    }
    
}

}
@media (max-width: 1200px) {
  .card-voucher {
    width: 450px;
}
}
@media (max-width: 1000px) {
  .card-voucher {
    width: 380px;
}
}

