.footer-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .p-1 {
      height: 20px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
      flex: none;
      order: 0;
      flex-grow: 0;
      text-align: end;
      margin-right: 4px;
    }
    .p-2 {
      height: 20px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #2949a9;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
  