.feedbacks{
    margin-top: 19px;
}

.feedback {
  align-items: initial !important;
  margin-top: 12.82px;
  display: flex;
  flex-direction: row;

  &__info {
    color: #6C757D;
  }

  &__avatar {
  }

  &__info {
    font-weight: 700;

  }

  &__name {
    font-size: 15px;
    line-height: 22px;
  }

  &__description {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin: 0 !important;
  }

  &__cta {
    color: #4F4F4F;
  }

  &__btn {
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
  }

  &__btn:not(:first-child) {
    margin-left: 16px;
  }
}

.feedback:first-child {
  margin-top: 0;
}
