.header-form {
  > h1 {
    width: 273px;
    height: 40px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: #101828;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 12px;
    margin-top: 12px;
  }
  > p {
    width: 342px;
    height: 24px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-bottom: 0;
  }
}
