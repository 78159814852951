.container{
    background-color: #f8f8f8;
    color: #2148c1;
    padding-left: 20px;
    padding-top: 15px;
    .title {
        font-family: initial;
        font-weight: bold;
        font-size: 30px;
    }
    
}

.contact {
    font-size: 15px;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin-left: 10%;
    .item{
        font-size: 20px;
        margin-right: 10px;
    }
}
.text {
    width: 80%;
    font-size: 20px;   
}
.col {
    padding: 10px;
    text-align: center;
}
