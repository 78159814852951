.menu-left {
  width: 360px;
  height: 100%;
  position: relative;
  background-color: aqua;
  border-right: 1px solid #424242;

  &__title {
    font-size: x-large;
    font-weight: 800;
    font-family: inherit;

  }
}

.container {
  align-items: center;
  width: 100%;
  padding: 0 0 0 15em;
}

@media only screen and (max-width: 992px) {
  .container {
    padding: 0 0 0 0;
  }
}
.item {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  padding: 5px;

  &__text {
    width: 60cm;
  }
  &__logo {
    width: 60px;
    height: 60px;
    margin-right: 15px;
    border-radius: 50%;
  }

  &__logo:hover {
    opacity: 0.5;
  }
  &__logo:hover .tooltip {
    visibility: visible;

  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    font-family: inherit;
    line-height: 20px;
    color: #000000;
    margin-bottom: 5px;
  }

  &__title:hover .tooltip {
    visibility: visible;
  }
}

.item:hover {
  // border: 1px solid #636363;
  background-color: #ececec;
  cursor: pointer;
}
.people {
  background-color: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
  padding: 20px;
  max-width: 600px;
  border-radius: 14px;
  width: 100%;
  margin: 10px;
  color: #000000;

  &__header {
    color: #000000 !important;
    font-size: 20px;
    font-family: inherit;
    font-weight: 900;
    margin-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
  }

  &__list {
    margin-top: 35px;
  }


  &__bio {
    font-size: 14px;
    font-family: inherit;
    line-height: 18px;
    color: #636363
  }
}

.view-more {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  color: #1e4db7;
  border: 0.7664px solid #1e4db7 !important;
  border-radius: 7px !important;
  margin: 5px;
  padding: 0 !important;
  height: 30px;
}

.view-more:hover {
  background-color: #1e4db7;
  color: #fff;
}

.tooltip {
  width: 18em;
  box-shadow: #636363;
  border-radius: 5px;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #636363;
  left: 15%;
  padding: 0 0 10px 0;
  &__top {
    display: flex;
  }

  &__top-left {
    width: 7em;
    padding: 15px 0 0 20px;

  }

  &__top-logo {
    width: 90px;
    height: 90px;
    margin-right: 15px;
    border-radius: 50%;
  }

  &__top-logo:hover {
    opacity: 0.5;
  }

  &__top-right {
    padding: 10px;
  }

  &__top-title {
    font-size: 25px;
    font-weight: 700;
    font-family: inherit;
    line-height: 40px;
    color: #000000;
    margin-bottom: 5px;
  }
  &__frendy{
    padding: 8px;
    font-size: 20px;
    display: flex;
    color: #606366
  }

  &__footer{
    display: flex;
    justify-content: space-evenly;
  }

  &__button{
    font-weight: 700;
    width: 10em;
  }
}