.notificationBox {
  background-color: white;
  border: solid 1px rgb(241, 233, 233);
  border-radius: 15px;
  margin-top: 13px;
  width: 25rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 5px;
  .title {
    font-size: 16px;
    color: #565656;
    padding: 5px;
    font-weight: 600;
    border-bottom: solid 1px rgb(241, 233, 233);
    display: flex;
    justify-content: space-between;
  }

  .content {
    padding-top: 5px;
  }
}

//style notification Staff

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: rgb(245, 245, 245);
  color: black;
}

.wrapper {
  .notifications__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin-bottom: 1px;
    padding: 5px 5px;
    background-color: white;
    box-shadow: 0px 15px 20px 0px rgb(0, 0, 0, 0.2);
    transition: all 0.3s ease-in;
    cursor: pointer;
  }
  .notifications__item__seen {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 5px 5px;
    margin-bottom: 1px;
    background-color: rgb(216, 232, 252);
    box-shadow: 0px 15px 20px 0px rgb(0, 0, 0, 0.2);
    transition: all 0.3s ease-in;
    cursor: pointer;
  }

  .notifications__item__avatar {
    width: 65px;
    height: 65px;
    overflow: hidden;
    margin-left: 5px;
    border-radius: 50%;
  }

  .notifications__item__avatar img {
    width: 100%;
    height: 100%;
  }

  .notifications__item__content {
    width: calc(100% - 105px);
    margin-left: 10px;
  }
  .notifications__item__title,
  .notifications__item__message {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-size: 18px;
  }

  .notifications__item__title {
    letter-spacing: 1px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
  }

  .notifications__item__message {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    color: black;
  }
  .notifications__item__button {
    width: 35px;
    height: 100%;
  }

  .notifications__item__option {
    width: 20px;
    height: 20px;
    margin: 8px 0;

    border-radius: 50%;
    color: white;
    opacity: 0;

    font-size: 10px;
    text-align: center;
    line-height: 20px;

    cursor: pointer;
    transition: all 0.2s;
  }

  /*
  * Animation part
  */
  .notifications__item:hover {
    background-color: #c4c2c2;
    box-shadow: 0px 5px 10px 0px rgb(0, 0, 0, 0.2);
  }
  .notifications__item__seen:hover {
    background-color: #6cabfd;
  }
  .notifications__item .hover-button {
    display: none;
  }

  .notifications__item:hover .hover-button {
    display: block;
  }
  // @keyframes archiveAnimation{
  //   to {
  //     transform: translateX( 100px );
  //     opacity: 0;
  //   }
  // }
  // @keyframes deleteAnimation{
  //   to {
  //     transform: translateX( -100px );
  //     opacity: 0;
  //   }
  // }
}

// Style notification list

.wrapperList {
  .notifications__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 5px 5px;
    background-color: white;
    transition: all 0.3s ease-in;
    cursor: pointer;
  }
  .notifications__item__seen {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 5px 5px;
    background-color: rgb(194, 220, 253);
    box-shadow: 0px 15px 20px 0px rgb(0, 0, 0, 0.2);
    transition: all 0.3s ease-in;
    cursor: pointer;
  }

  .notifications__item__avatar {
    width: 65px;
    height: 65px;
    overflow: hidden;
    margin-left: 5px;
    border-radius: 50%;
  }

  .notifications__item__avatar img {
    width: 100%;
    height: 100%;
  }

  .notifications__item__content {
    width: calc(95% - 105px);
    margin-left: 10px;
  }
  .notifications__item__button {
    width: 35px;
    height: 100%;
  }

  .notifications__item__message {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    color: black;
  }

  .notifications__item__option {
    width: 20px;
    height: 20px;
    margin: 8px 0;

    border-radius: 50%;
    color: white;
    opacity: 0;

    font-size: 10px;
    text-align: center;
    line-height: 20px;

    cursor: pointer;
    transition: all 0.2s;
  }

  /*
  * Animation part
  */
  .notifications__item:hover {
    background-color: #c4c2c2;
  }
  .notifications__item__seen:hover {
    background-color: #6cabfd;
  }
  .notifications__item .hover-button {
    display: none;
  }

  .notifications__item:hover .hover-button {
    display: block;
  }

  // @keyframes archiveAnimation{
  //   to {
  //     transform: translateX( 100px );
  //     opacity: 0;
  //   }
  // }
  // @keyframes deleteAnimation{
  //   to {
  //     transform: translateX( -100px );
  //     opacity: 0;
  //   }
  // }
}
