.header-form {
  > img {
    width: 51.32px;
    height: 47.23px;
  }
  > div {
    height: 76px;
    > h1 {
      height: 40px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.01em;
      color: #101828;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-bottom: 12px;
      margin-top: 32px;
    }
    > p {
      height: 24px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #667085;
      margin-bottom: 0;
      word-break: break-all;
    }
  }
}
