/* custom modal */
.ant-btn-primary {
    border-color: #1e4db8 !important;
    background: #1e4db8 !important;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: #fff !important;
    border-color: #2e64e9 !important;
    background: #2e64e9 !important;
}

.ant-btn-dangerous {
    color: #fff !important;
    border-color: #ff4d4f !important;
    background: #ff4d4f !important;
}

.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
    color: #fff !important;
    border-color: #ff7875 !important;
    background: #ff7875 !important;
}

.ant-modal-content {
    border-radius: 12px !important;
    overflow: hidden !important;
}

.ant-tabs-dropdown-menu .ant-tabs-dropdown-menu-item .tab-sell {
    color: black !important;
}

/* custom modal */

/* custom select */
.ant-select-selector {
    border-radius: 5px !important;
}

.select-product-sell .ant-select-arrow {
    left: 11px !important;
}

.select-product-sell .ant-select-selector {
    padding-left: 31px !important;
}

.select-product-sell .ant-select-selector .ant-select-selection-search {
    padding-left: 20px !important;
}

.dropdown-select-search-product {
    min-width: 400px !important;
}

.dropdown-select-search-product .ant-select-item {
    padding: 0px !important;
}

/* custom select */

.br-15__date-picker {
    border-radius: 5px !important;
}

/* custom button */
.ant-btn {
    border-radius: 8px !important;
}

.br-15__button {
    border-radius: 5px !important;
}

/* custom button */

/* custom input */
.br-15__input {
    border-radius: 5px !important;
}

.ant-input {
    border-radius: 5px !important;
}

.ant-input-number {
    border-radius: 5px !important;
}

.ant-input-affix-wrapper {
    border-radius: 5px !important;
}

.ant-picker {
    border-radius: 5px !important;
}

.show-handler-number .ant-input-number-handler-wrap {
    opacity: 1 !important;
}

.input .ant-input-affix-wrapper {
    border-radius: 50px !important;
}

.edit-form-item-register .ant-form-item {
    margin-bottom: 0 !important;
}

.edit-form-item-register .ant-col {
    max-height: 50px !important;
}

.edit-form-item-register .ant-form-item-explain-error {
    margin-top: 5px !important;
}

/* custom input */

.ant-popover {
    z-index: 1500;
}

.ant-menu-item-only-child {
    height: max-content !important;
}

.ant-menu-submenu-vertical > .ant-menu-submenu-title {
    height: 60px !important;
}

/* custom menu */

.upload-variant-image > .ant-upload {
    width: 72px !important;
    height: 72px !important;
}

.upload-category-image > .ant-upload {
    width: 150px !important;
    height: 150px !important;
}

.upload-shipping > .ant-upload {
    width: 125px !important;
    height: 125px !important;
}

/* custom upload antd */

.ant-upload-list-item-actions {
    display: flex !important;
    align-items: center !important;
}

.image-product .ant-upload-list-item-actions {
    opacity: 1 !important;
}

.ant-upload-list-item-info::before {
    left: 0 !important;
}

.image-product .ant-upload-list-item-info::before {
    opacity: 1 !important;
}

.ant-upload-list-picture-card-container {
    width: 85px !important;
    height: 85px !important;
}

.image-product .ant-upload-list-item-card-actions-btn {
    opacity: 1 !important;
}

/* custom upload antd */

/* custom tabs */
.tabs-login .ant-tabs-nav::before {
    border-bottom: none !important;
}

.tabs-login .ant-tabs-ink-bar {
    background-color: white !important;
}

.tabs-login .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    width: 0px !important;
    transition: none;
}

.ant-tabs-nav-wrap {
    padding-top: 5px;
}

.tabs-invoices .ant-tabs-nav::before {
    border-bottom: 0 !important;
}

.tabs-invoices .ant-tabs-nav {
    margin-bottom: 0px !important;
}

.tabs-invoices .ant-tabs-nav-wrap {
    padding-top: 0px !important;
}

.tabs-invoices .ant-tabs-tab div {
    overflow: hidden !important;
}

.tabs-invoices .ant-tabs-tab {
    background-color: #192da0 !important;
    border-color: #192da0 !important;
}

.tabs-invoices .ant-tabs-tab.ant-tabs-tab-active {
    background-color: white !important;
    border-color: white !important;
}

.tabs-invoices {
    max-width: 550px;
    min-width: 550px;
}

.tabs-invoices .ant-tabs-tab {
    max-width: 120px !important;
    min-width: 120px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.tabs-invoices .ant-tabs-nav-add {
    position: relative !important;
    border: 0 !important;
    background-color: transparent !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.tabs-login .ant-tabs-nav {
    margin: 0px !important;
}

/* custom tabs */

/* custom popover */
.ant-popover-arrow {
    display: none;
}

.table-product-in-order thead > tr > th {
    background-color: white !important;
}

.table-product-in-order .ant-table {
    margin-left: 0px !important;
}

/* custom popover */

/* custom submenu arrow */
.edit-submenu-arrow .ant-menu-submenu-arrow {
    display: none;
}

.edit-submenu-arrow.ant-tooltip-arrow {
    display: none !important;
}

.edit-submenu-arrow .ant-menu-item-only-child {
    height: 40px !important;
}

/* Collapse Panel */
.edit-collapse-panel .ant-collapse-header {
    padding: 10px 0px !important;
}

/* Collapse Panel */


sup.ant-scroll-number.ant-badge-count.ant-badge-count-sm {
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
    padding: 5px;
    border: 2px solid white;
    background: #5ecfff;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.ant-menu-item a, .ant-menu-item span[role=img], .ant-menu-submenu-title span {
    color: rgba(110, 110, 110, 0.85) !important;
}

.ant-menu-item:hover, .ant-menu-item-selected {
    background: none !important;
}

.ant-menu-item:hover a, .ant-menu-item:hover span[role=img], .ant-menu-title-content:hover, .ant-menu-submenu-title:hover span[role=img], .ant-menu-submenu-selected, .ant-menu-item-selected a {
    color: #1E4DB7 !important;
}

.ant-menu-submenu .ant-menu-title-content a {
    font-size: 15px !important;
    font-weight: 500 !important;
}

.ant-menu-item:hover::after, .ant-menu-item-selected:after {
    border-right: 6px solid #1e4db7 !important;
    border-radius: 5px 0px 0px 5px !important;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 28px !important;
    line-height: 28px !important;
}

.ant-menu-item, .ant-menu-submenu {
    margin-bottom: 15px !important;
}

.ant-layout-sider-collapsed ul {
    padding-left: 0 !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    display: flex !important;
}


/* CSS registerBusiness */
.ant-steps-item-container {
    display: flex !important;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding-bottom: unset !important;
    margin-left: auto !important;
}

.ant-steps-item:first-child::after {
    border-left: none !important;
}

.ant-steps-item:first-child {
    border-top-left-radius: 10px !important;
}

.ant-steps-item:last-child {
    margin-right: 0 !important;
}

.ant-steps-item:last-child {
    border-top-right-radius: 8px !important;
}

.ant-steps-item::before {
    content: "" !important;
    position: absolute !important;
    right: -20px !important;
    left: auto !important;
    bottom: 0 !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent !important;
    border-left: 20px solid #e3e5e5 !important;
    border-top: 20px solid transparent !important;
    border-bottom: 20px solid transparent !important;
    z-index: 1 !important;

}

.ant-steps-item::after,
.ant-steps-item:last-child::before {
    content: "" !important;
    position: absolute !important;
    left: 0 !important;
    top: auto !important;
    bottom: 0 !important;
    width: 0 !important;
    height: 0 !important;
    transform: none !important;
    border: none !important;
    border-left: 20px solid #f0f2f5 !important;
    border-top: 20px solid transparent !important;
    border-bottom: 20px solid transparent !important;
    z-index: 0 !important;
}

.ant-steps-item .ant-steps-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}


.ant-steps-item {
    background: #e3e5e5 !important;
    margin-right: 8px;
    height: 40px;
}

.ant-steps-item-title {
    font-weight: bold;
    color: #91919f !important;
}

.ant-steps-item-active,
.ant-steps-item-finish {
    background: #0bb2fb !important;
    margin-right: 8px;
    height: 40px;
}

.ant-steps-item-active::before,
.ant-steps-item-finish::before {
    border-left: 20px solid #0bb2fb !important;
}

.ant-steps-navigation {
    margin-top: 40px !important;
}

.ant-steps-item-active .ant-steps-item-title,
.ant-steps-item-finish .ant-steps-item-title {
    color: white !important;
}

.ant-steps-item-active .ant-steps-item-icon,
.ant-steps-item-finish .ant-steps-item-icon {
    background-color: white !important;
}

.ant-steps-item-icon {
    width: 26px !important;
    height: 26px !important;
    line-height: 23px !important;
    border: 0 !important;
    background-color: #91919f !important;
}

.ant-steps-item-active .ant-steps-icon,
.ant-steps-item-finish .ant-steps-iicon {
    color: #1e4db7 !important;
}

.ant-steps-icon {
    color: #dedfe0 !important;
    font-weight: bold;
}

#TTKD .ant-upload {
    border-radius: 10px !important;
    background-color: #cdcdcd;
    border: 0;
}

#TTCH .ant-form-item-control-input-content .ant-input {
    border-radius: 0 !important;
    width: 350px;
    height: 40px;
}

.ant-form-item-label {
    flex-grow: 0;
    font-weight: bold;
}

#TTKD .ant-upload-select {
    width: 100% !important;
    height: 176px !important;
}

#TTKD .ant-row-no-wrap {
    background-color: white !important;
    height: 80px !important;
}

.ant-steps-item-disabled {
    cursor: default !important;
}

input.ant-input {
    /* font-family: 'Inter' !important; */
    color: #101828 !important;
}

input.ant-input::placeholder {
    color: #667085 !important;
}
.ant-form-item-explain-error {
    color: #F04438 !important;
}
.ant-btn {
    border-color: #335DB3 !important;
}

.ant-input-affix-wrapper {
    border-color: #D0D5DD !important;
}

.ant-input-affix-wrapper-focused {
    border-color: #335BD3 !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px !important;
}

.ant-input-affix-wrapper-status-error {
    border-color: #F04438 !important;
}

.ant-form-vertical .ant-form-item-label {
    padding-bottom: 6px !important;
}

.input-phone-antd {
    width: 60px !important;
}

.input-phone-antd > input {
    height: 25.14px !important;
}

.input-phone-antd.ant-select-open {
    border-color: #fff !important;
}
.input-phone-antd .ant-select-selector {
    border-color: #fff !important;
    box-shadow: unset !important;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
}

.input-phone-antd .ant-select-selector {
    height: 25.14px !important;
}
.input-phone-antd .ant-select-selector .ant-select-selection-item {
    line-height: 25.14px !important;
}
.input-phone-antd.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: #fff !important;
}